<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="5"></top>
      <img src="../assets/image/banner3.jpg" width="100%" alt="" />
      <div class="container-box width-100">
        <div class="width-100 flex-row justify-content-around align-items-center">
          <div class="left" @click="$router.push({ path: '/onlineLearningDetail', query: { id: learnVideoList[0].id } })">
            <el-image class="picture margin-b" :src="learnVideoList[0]?.image" fit="cover"></el-image>
            <p class="width-100 fs-big black fw-mid cut-text">{{ learnVideoList[0]?.title }}</p>
            <div class="width-100 margin-b margin-ts flex-row justify-content-spaceBetween align-items-center">
              <p class="fs-mid darkgrey opacity-8">共{{ learnVideoList[0]?.viewsNum }}人学习</p>
              <el-button type="primary" size="small">观看学习</el-button>
            </div>
          </div>
          <div class="right flex-column justify-content-spaceBetween align-items-center">
            <div class="width-100" @click="$router.push({ path: '/onlineLearningDetail', query: { id: learnVideoList[1].id } })">
              <el-image class="picture margin-b" :src="learnVideoList[1]?.image" fit="cover"></el-image>
              <p class="width-100 fs-big black fw-mid cut-text">{{ learnVideoList[1]?.title }}</p>
            </div>
            <div class="divider-h"></div>

            <div class="width-100" @click="$router.push({ path: '/onlineLearningDetail', query: { id: learnVideoList[2].id } })">
              <el-image class="picture margin-b" :src="learnVideoList[2]?.image" fit="cover"></el-image>
              <p class="width-100 fs-big black fw-mid cut-text">{{ learnVideoList[2]?.title }}</p>
            </div>
          </div>
        </div>
        <div class="width-100">
          <el-row>
            <el-col :span="8" v-for="(item, index) in learnVideoList.slice(3)" :key="index"
              ><div class="grid-content">
                <el-image class="picture margin-b" :src="item.image" fit="cover"></el-image>
                <p class="width-100 fs-big black fw-mid cut-text">{{ item.title }}</p>
                <div class="width-100 margin-ts flex-row justify-content-spaceBetween align-items-center">
                  <p class="fs-mid darkgrey opacity-8">共{{ item.viewsNum }}人学习</p>
                  <el-button @click="$router.push({ path: '/onlineLearningDetail', query: { id: item.id } })" type="primary" size="small"
                    >观看学习</el-button
                  >
                </div>
              </div></el-col
            >
          </el-row>
        </div>
        <div v-if="moreFlag" @click="nextPage" class="width-100 textAlign-c"><el-button plain class="more-button">查看更多</el-button></div>
        <div v-else class="textAlign-c margin-t darkgrey">已经到底了</div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { learnVideoList } from '@/api/TranLearnVideo';
export default {
  name: 'onlineLearning',
  components: {
    top,
    bottom
  },
  data() {
    return {
      learnVideoList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 9
      },
      moreFlag: true
    };
  },
  created() {
    this.learnVideoList = [];
    this.getLearnVideoListData();
  },
  methods: {
    getLearnVideoListData() {
      learnVideoList(this.queryParams).then((res) => {
        const rows = res.rows.map(row => {
          let image = row.image;
          if (row.image && !row.image.startsWith('http')) {
            image = `${process.env.VUE_APP_IMAGE_PREFIX}${image}`
          }
          return {
            ...row,
            image
          }
        })
        this.learnVideoList.push(...rows);
        if (rows.length < this.queryParams.pageSize) {
          this.moreFlag = false;
        }
      });
    },
    nextPage() {
      this.queryParams.pageNum++;
      this.getLearnVideoListData();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0 80px;
    .title,
    span {
      font-size: 36px;
      font-weight: bold;
    }
    .left {
      width: 68%;
      padding: 20px 20px;
      background: white;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(133, 188, 240, 0.25);
      height: 700px;
      margin-bottom: 20px;
      .picture {
        width: 100%;
        height: 550px;
      }
    }
    .right {
      width: 28%;
      padding: 20px 20px;
      background: white;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(133, 188, 240, 0.25);
      height: 700px;
      margin-bottom: 20px;
      .picture {
        width: 100%;
        height: 250px;
      }
    }
    .grid-content {
      width: 94%;
      padding: 20px 20px;
      margin: 15px 3%;
      background: white;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(133, 188, 240, 0.25);
      .picture {
        width: 100%;
        height: 300px;
      }
    }
  }
}
</style>
